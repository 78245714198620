import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="Promotions Terms and Conditions" />
    <div className="common-page privacy-page">
      <HeadingSection h1="Promotions Terms and Conditions" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <h2>Promotion Terms and Conditions</h2>
      <h4>Who qualifies as an Eligible Person?</h4>
      <p>
        You qualify for the promotion when you download Sprive, successfully sign-up by setting up a Sprive e-wallet account and have an active direct debit in place. You must enter a valid promo code whilst onboarding.
      </p>
      <h4>What is the promotion bonus amount?</h4>
      <p>
        The promotion bonus will vary depending on the campaign you are enrolled in.
      </p>
      <h4>When will I be credited the bonus?</h4>
      <p>
      Your bonus will be deposited into your Sprive account within an hour of signing up to Sprive by setting up an e-wallet account, having an active direct debit in place, and entering your promo code during onboarding.
      </p>
      <h4>Can I withdraw the bonus?</h4>
      <p>
      The promotion bonus is not eligible to be withdrawn to your personal bank account, it can only be used to pay towards your mortgage through the Sprive app.
      </p>
      <h4>How long does the promotional offer last for?</h4>
      <p>
      Each campaign has different durations. You can check the duration of the campaign from where you found the promotion. 
      </p>
      <h4>How many times can I use a sign-up promotional offer?</h4>
      <p>
      You can only use a sign-up promotional offer once. You need to be a new customer to Sprive. A new customer is defined as someone who has never created a Sprive account before.
      </p>
      <h4>You may not:</h4>
      <p>
        <ul>
          (a) create duplicate account for the promotion;
        </ul>
        <ul>
          (b) use alternative contact information to use the promotion;
        </ul>
        <ul>
          (c) do anything that might damage Sprive’s brand, goodwill or reputation;
        </ul>
      </p>
      <h4>What other legal information should I know?</h4>
      <p>
      Sprive reserves the right to limit the invite promotion if we suspect you did not use the promotion in good faith, or have breached any of the above terms. In cases of suspected fraud, further action may result in the suspension of your Sprive account, and your Bonus being withheld from being paid to you. If you believe you’re owed a Promotion Bonus that hasn’t been paid, please email us at <a href="mailto:hello@sprive.com" type="email">
              hello@sprive.com
          </a>. In most cases these issues can be quickly investigated and resolved.
      </p>
      <p>
      We may, at our sole discretion, suspend, terminate or change the terms and requirements of the Promotion at any time. These terms are published in English, and any translation is provided as a courtesy only. Participants in the Promotion cannot derive any rights from the translated version. The English language version of these terms shall apply and prevail and be conclusive and binding. The English version shall be used in all legal proceedings to the extent permitted by law.
      </p>
      <p>These Promotion Terms shall be exclusively governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these terms shall exclusively be submitted to and dealt with by the competent court in England.</p>
      <p>In order to benefit from this Promotion, you must be a new customer to Sprive and have agreed to our General Terms and Conditions. Any additional information provided by you in the course of a referral will be treated in line with the Terms & Conditions and Data Privacy Policy.</p>

    </div>
  </section>
);

export default PrivacyPage;
